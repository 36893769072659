<script setup lang="ts">
import type { Component } from "vue";
import { computed } from "vue";

import ShortcutStrokes from "~/components/dumb/ShortcutStrokes.vue";
import { getStrokesForPlatform } from "~/constants/command";
import { ChevronRightIcon } from "~/icons";
import type { CommandId } from "~/shared/enums";
import { usePageStore } from "~/stores";

import { copyAndNotify } from "../notifications";

const props = defineProps<{
  title: string;
  icon?: Component;
  iconArgs?: object;
  selected?: boolean;
  disabled?: boolean;
  subtitle?: string;
  subtitleCopyDescription?: string;
  commandId?: CommandId;
  isSubmenu?: boolean;
  isMultiSelect?: boolean;
  dataTestid?: string;
}>();

const pageStore = usePageStore();

const strokes = computed(() => (props.commandId ? getStrokesForPlatform(props.commandId, pageStore.isMac) : undefined));

const onClick = (event: Event) => {
  if (pageStore.isMobile || !props.isSubmenu) {
    return;
  }
  event.stopPropagation();
};

const onClickSubtitle = (event: Event) => {
  if (!props.subtitle || !props.subtitleCopyDescription) {
    return;
  }
  event.stopPropagation();
  event.preventDefault();
  copyAndNotify(props.subtitleCopyDescription, props.subtitle);
};
</script>

<template>
  <div
    :data-testid="dataTestid"
    class="flex w-full max-w-full items-center"
    :class="!isMultiSelect && 'py-1 pl-3 pr-2'"
    @click="onClick"
    @keydown.enter="onClick">
    <component
      :is="icon"
      v-if="icon"
      class="mr-2 shrink-0"
      :class="[!iconArgs && `icon-sm ${selected ? 'text-primary-base' : 'text-lt'}`, disabled ? 'opacity-50' : '']"
      v-bind="iconArgs"
      aria-hidden="true" />
    <div class="mr-2 flex-col overflow-hidden">
      <div :title="title" class="select-none truncate text-sm" :class="isMultiSelect && 'text-md'">
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        :title="subtitle"
        class="flex-1 select-none truncate text-xs text-lt"
        @click="onClickSubtitle"
        @keydown.enter="onClickSubtitle">
        {{ subtitle }}
      </div>
    </div>

    <div class="flex flex-1 justify-end gap-1">
      <ShortcutStrokes :strokes="strokes" :disabled="disabled === true" />
      <ChevronRightIcon v-if="isSubmenu" class="text-vlt icon-sm" />
    </div>
  </div>
</template>
