<script setup lang="ts">
import type { Filter, FilterDefinition } from "~/shared/types";

defineProps<{
  definition: FilterDefinition;
  propertyDuid: Filter["propertyDuid"];
  absentee?: boolean;
}>();
</script>

<template>
  <div
    v-if="!!definition"
    class="dart-filter-field ml-px flex h-full max-w-40 select-none items-center gap-1 overflow-hidden text-sm"
    :class="absentee ? 'text-lt' : 'text-md'">
    <component :is="definition.icon" class="icon-sm" :class="absentee ? 'text-vlt' : 'text-lt'" aria-hidden="true" />
    <span class="truncate" :title="definition.title">{{ definition.title }}</span>
  </div>
</template>
