<script setup lang="ts">
import moment, { type Moment } from "moment";
import { computed, onUnmounted, ref, watch, watchEffect } from "vue";

import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import Input from "~/components/dumb/Input.vue";
import Modal from "~/components/dumb/Modal.vue";
import PageIcon from "~/components/dumb/PageIcon.vue";
import PageIconPicker from "~/components/dumb/PageIconPicker.vue";
import PermissionManager from "~/components/dumb/PermissionManager.vue";
import RadioCardGroup from "~/components/dumb/RadioCardGroup.vue";
import RecurrenceEditor from "~/components/dumb/RecurrenceEditor.vue";
import Toggle from "~/components/dumb/Toggle.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { THROTTLE_MS } from "~/constants/app";
import { BlockedIcon, InfoIcon, SprintIcon, TrashIcon } from "~/icons";
import {
  ButtonSize,
  ButtonStyle,
  DialogMode,
  IconKind,
  ModalWidth,
  PageKind,
  Placement,
  SpaceKind,
  SprintMode,
} from "~/shared/enums";
import type { Page, Recurrence, Space, SpaceUpdate } from "~/shared/types";
import { useAppStore, useDataStore, useTenantStore } from "~/stores";
import { validateIsNotEmpty } from "~/utils/common";
import { getEmojiRecommendation } from "~/utils/recommendation";
import { ThrottleManager } from "~/utils/throttleManager";
import { getNextRecursNextAt, getPrevRecursNextAt } from "~/utils/time";

const DISABLED_STYLE = "pointer-events-none select-none opacity-50";

const DISCARD_DRAFT_DIALOG_DESCRIPTION =
  "Discarding the draft will clear the space information you've put in so far. This can't be undone. Are you sure you want to proceed?";
const DISABLE_SPRINTS_DIALOG_DESCRIPTION =
  "Disabling sprints will remove them for everyone in the workspace and move all the tasks in them to the trash. This can't be undone. Are you sure you want to proceed?";
const SPRINT_NAME_FORMAT_DESCRIPTION =
  "The format of the sprint name. Use {N} for the sprint number, {S} for the start date, and {F} for the finish date.";
const SPRINT_REPLICATE_ON_ROLLOVER_DESCRIPTION =
  "By default, unfinished tasks will be moved to the new sprint. Enabling replication will create copies of the tasks in the new sprint instead.";

const NO_SPRINTS_VALUE = "No sprints";
const SPRINTS_VALUE = "Sprints";

const appStore = useAppStore();
const dataStore = useDataStore();
const tenantStore = useTenantStore();

const discardDraftDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);
const disableSprintsDialog = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const sprintsDescription = computed(
  () =>
    `Sprints help organize work. Current tasks go in Active${
      tenantStore.backlogEnabled
        ? ", near-term ones in Next, and long-term ones in Backlog"
        : " and near-term ones in Next"
    }. When you complete the Active tasks, start the next sprint.`
);

const formatAbbreviation = (title: string) => title.replace(/\s+/g, "").substring(0, 4).toUpperCase() || "MAR";

const title = ref(appStore.spaceOpenInModal?.title ?? "");
const abbreviation = ref(appStore.spaceOpenInModal?.abrev ?? "");
const description = ref(appStore.spaceOpenInModal?.description ?? "");
const workingAbbreviation = computed(() =>
  abbreviation.value.trim().length > 0 ? abbreviation.value : formatAbbreviation(title.value)
);
const sprintNameFmt = ref(appStore.spaceOpenInModal?.sprintNameFmt ?? "");

const getIsGeneralOrPersonalSpace = (space: Space | null) =>
  space?.kind === SpaceKind.WORKSPACE || space?.kind === SpaceKind.PERSONAL;

const createEnabled = ref(false);
const tooltipMessage = ref("");

watchEffect(() => {
  createEnabled.value = false;
  tooltipMessage.value = "";
  if (title.value.trim().length === 0) {
    tooltipMessage.value = "Title is required";
    return;
  }
  if (
    dataStore
      .getSpaceList()
      .some((e) => e.duid !== appStore.spaceOpenInModal?.duid && e.abrev === workingAbbreviation.value)
  ) {
    tooltipMessage.value = `There is already a space with the abbreviation ${workingAbbreviation.value}`;
    return;
  }
  createEnabled.value = true;
  tooltipMessage.value = "Save space";
});

const updateField = (spacePartial: Partial<Space>) => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  dataStore.updateSpace({ duid: appStore.spaceOpenInModal.duid, ...spacePartial });
};

const saveTitleManager = new ThrottleManager(() => {
  updateField({ title: title.value.trim() });
}, THROTTLE_MS);

const updateTitle = (newValue: string) => {
  if (newValue === title.value) {
    return;
  }
  title.value = newValue;
  saveTitleManager.run();
};

const saveAbrevManager = new ThrottleManager(() => {
  updateField({ abrev: formatAbbreviation(abbreviation.value) });
}, THROTTLE_MS);

const updateAbbreviation = (newValue: string) => {
  const newAbbrev = formatAbbreviation(newValue);
  if (newAbbrev === abbreviation.value) {
    return;
  }
  abbreviation.value = newAbbrev;
  saveAbrevManager.run();
};

const saveDescriptionManager = new ThrottleManager(() => {
  updateField({ description: description.value.trim() });
}, THROTTLE_MS);

const updateDescription = (newValue: string) => {
  if (newValue === description.value) {
    return;
  }
  description.value = newValue;
  saveDescriptionManager.run();
};

const saveSprintNameFmtManager = new ThrottleManager(() => {
  updateField({ sprintNameFmt: sprintNameFmt.value.trim() });
}, THROTTLE_MS);

const updateSprintNameFmt = (newValue: string) => {
  if (newValue === "" || newValue === sprintNameFmt.value) {
    return;
  }
  sprintNameFmt.value = newValue;
  saveSprintNameFmtManager.run();
};

const closeModal = () => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  saveTitleManager.finish();
  saveAbrevManager.finish();
  saveDescriptionManager.finish();

  appStore.setSpaceOpenInModal(null);
};

const confirmDiscardDraft = () => {
  if (appStore.spaceOpenInModal?.drafterDuid) {
    discardDraftDialog.value?.openModal();
    return;
  }

  closeModal();
};

const handleConfirmDiscardDraft = () => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  dataStore.deleteSpace(appStore.spaceOpenInModal);
  closeModal();
};

const updateSprintMode = (newSprintMode: SprintMode, override = false) => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  if (newSprintMode === SprintMode.NONE && !(appStore.spaceOpenInModal?.drafterDuid || override)) {
    disableSprintsDialog.value?.openModal();
    return;
  }

  dataStore.updateSpaceSprintMode(appStore.spaceOpenInModal.duid, newSprintMode);
};

const normalizeRecursNextAt = (recursNextAt: Moment, recurrence: Recurrence) => {
  const now = moment();
  let res = recursNextAt;
  for (let i = 0; i < 1000; i += 1) {
    res = getPrevRecursNextAt(recurrence, res);
    if (res.isBefore(now)) {
      break;
    }
  }
  for (let i = 0; i < 1000; i += 1) {
    res = getNextRecursNextAt(recurrence, res);
    if (res.isAfter(now)) {
      break;
    }
  }
  return res;
};

const updateRecurrence = (newRecurrence: Recurrence | null, isStandup: boolean) => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  let recursNextAt = null;
  if (newRecurrence) {
    const start = moment(
      (isStandup ? appStore.spaceOpenInModal.standupRecursNextAt : appStore.spaceOpenInModal.changelogRecursNextAt) ??
        undefined
    ).startOf("minute");
    const remainder = (15 - (start.minute() % 15)) % 15;
    recursNextAt = normalizeRecursNextAt(start.add(remainder, "minutes"), newRecurrence);
  }
  const recursNextAtStr = recursNextAt ? recursNextAt.toISOString() : null;

  if (isStandup) {
    updateField({ standupRecurrence: newRecurrence, standupRecursNextAt: recursNextAtStr });
  } else {
    updateField({ changelogRecurrence: newRecurrence, changelogRecursNextAt: recursNextAtStr });
  }
};

const updateRecursNextAt = (newRecursNextAt: string | null, isStandup: boolean) => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  const recurrence = (
    isStandup ? appStore.spaceOpenInModal.standupRecurrence : appStore.spaceOpenInModal.changelogRecurrence
  ) as Recurrence;
  const recursNextAt = newRecursNextAt
    ? normalizeRecursNextAt(moment(newRecursNextAt), recurrence).toISOString()
    : null;
  if (isStandup) {
    updateField({
      standupRecursNextAt: recursNextAt,
    });
  } else {
    updateField({
      changelogRecursNextAt: recursNextAt,
    });
  }
};

const getEmojiRecommendationMaybe = async (newValue: string) => {
  if (!appStore.spaceOpenInModal || appStore.spaceOpenInModal.iconKind !== IconKind.NONE) {
    return;
  }

  saveTitleManager.cancel();
  const newTitle = newValue.trim();
  if (newTitle.length === 0) {
    return;
  }

  const spaceDuid = appStore.spaceOpenInModal.duid;
  const emojiRecUpdate = await getEmojiRecommendation(spaceDuid, newTitle);
  dataStore.updatePage({ duid: spaceDuid, title: newTitle, ...emojiRecUpdate }, PageKind.SPACE);
};

const finishSpaceUpdate = () => {
  if (!appStore.spaceOpenInModal) {
    return;
  }

  saveTitleManager.finish();
  saveAbrevManager.finish();

  const update: SpaceUpdate = { duid: appStore.spaceOpenInModal.duid, drafterDuid: null };
  if (appStore.spaceOpenInModal.abrev !== workingAbbreviation.value) {
    update.abrev = workingAbbreviation.value;
  }

  dataStore.updateSpace(update);
  closeModal();
};

const sprintOptions = computed(() => [
  {
    title: "No sprints (default)",
    description: "Manage tasks any way you like",
    value: NO_SPRINTS_VALUE,
    selected: appStore.spaceOpenInModal?.sprintMode === SprintMode.NONE,
    icon: BlockedIcon,
    dataTestid: "no-sprints-option",
  },
  {
    title: "Sprints",
    description: "Enable sprints to manage tasks on a cadence",
    value: SPRINTS_VALUE,
    selected: appStore.spaceOpenInModal?.sprintMode === SprintMode.ANBA,
    icon: SprintIcon,
    dataTestid: "sprints-option",
  },
]);

watch(
  () => appStore.spaceOpenInModal,
  (newSpace, oldSpace) => {
    if (!newSpace || newSpace.duid === oldSpace?.duid) {
      return;
    }

    title.value = newSpace.title;
    abbreviation.value = newSpace.abrev;
    description.value = newSpace.description;
  }
);

onUnmounted(() => {
  saveTitleManager.destroy();
  saveAbrevManager.destroy();
  saveDescriptionManager.destroy();
});
</script>

<template>
  <Modal
    :entity="appStore.spaceOpenInModal"
    :title="(space) => (space?.drafterDuid ? 'Create a space' : `Update ${space?.title ?? 'space'}`)"
    :width="ModalWidth.L"
    :description="
      (space) =>
        space?.drafterDuid
          ? ' Spaces are like teams or projects. Use them to organize dartboards, sprints, and teammates.'
          : undefined
    "
    close-text="Discard draft"
    @close="confirmDiscardDraft">
    <template #default="{ entity: space }">
      <ConfirmationDialog
        ref="discardDraftDialog"
        :mode="DialogMode.DELETE"
        title="Discard draft"
        :description="DISCARD_DRAFT_DIALOG_DESCRIPTION"
        confirm-text="Discard"
        cancel-text="Keep"
        :icon="TrashIcon"
        @confirm="handleConfirmDiscardDraft"
        @cancel="closeModal" />
      <ConfirmationDialog
        ref="disableSprintsDialog"
        title="Disable sprints"
        :description="DISABLE_SPRINTS_DIALOG_DESCRIPTION"
        confirm-text="Confirm"
        cancel-text="Cancel"
        :mode="DialogMode.DELETE"
        :icon="TrashIcon"
        @confirm="updateSprintMode(SprintMode.NONE, true)" />
      <template v-if="space">
        <div
          class="mt-2 flex flex-col gap-2"
          :class="getIsGeneralOrPersonalSpace(space) && DISABLED_STYLE"
          @click.stop
          @keydown.stop>
          <div class="font-medium text-md">Basics</div>
          <div class="flex items-center gap-2 pr-4 sm:pr-0">
            <PageIconPicker :page="space">
              <Tooltip text="Change icon">
                <span
                  class="flex size-9 items-center justify-center rounded border bg-std border-hvy hover:bg-opposite/10">
                  <PageIcon :page="space as Page" />
                </span>
              </Tooltip>
            </PageIconPicker>
            <Input
              :init-value="space.title"
              required
              placeholder="e.g. Marketing"
              label="Title"
              data-testid="space-title"
              class="flex-1"
              @change="updateTitle"
              @finalize="getEmojiRecommendationMaybe" />
            <Input
              :init-value="space.abrev"
              :placeholder="formatAbbreviation(space.title)"
              label="Abbreviation"
              data-testid="space-abrev"
              class="w-24"
              @change="updateAbbreviation" />
          </div>
        </div>
        <div class="flex w-full grow flex-col gap-5 overflow-y-auto py-2">
          <div :class="getIsGeneralOrPersonalSpace(space) && DISABLED_STYLE" class="-mt-1">
            <Input
              :init-value="space.description"
              placeholder="Add a description"
              label="Description"
              hide-label-nonempty
              class="mt-1 w-full"
              @change="updateDescription" />
          </div>

          <div class="flex flex-col gap-1">
            <div class="flex items-center gap-1">
              <div class="font-medium text-md">Sprints</div>
              <Tooltip :text="sprintsDescription" :placement="Placement.RIGHT_TOP" info :skidding="-10">
                <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
              </Tooltip>
            </div>
            <RadioCardGroup
              :items="sprintOptions"
              @select="(value) => updateSprintMode(value === SPRINTS_VALUE ? SprintMode.ANBA : SprintMode.NONE)" />
            <div v-if="space.sprintMode === SprintMode.ANBA" class="flex flex-col gap-4 pb-4 pt-2">
              <div v-if="space.sprintMode === SprintMode.ANBA" class="flex justify-between gap-2">
                <div class="flex h-fit items-center gap-1">
                  <span class="text-sm text-md">Sprint name format</span>
                  <Tooltip :text="SPRINT_NAME_FORMAT_DESCRIPTION" :placement="Placement.RIGHT_TOP" info :skidding="-10">
                    <InfoIcon class="cursor-help rounded-full outline-none bg-std text-vlt icon-xs" />
                  </Tooltip>
                </div>
                <Input
                  :init-value="space.sprintNameFmt"
                  placeholder="Sprint {N}"
                  label="Description"
                  hide-label
                  hide-error
                  :validate="validateIsNotEmpty"
                  @change="updateSprintNameFmt" />
              </div>
              <Toggle
                :value="space.sprintReplicateOnRollover"
                label="Replicate tasks when starting next sprint"
                :description="SPRINT_REPLICATE_ON_ROLLOVER_DESCRIPTION"
                description-in-tooltip
                :size="ButtonSize.SMALL"
                text-sm
                @update="(newValue) => updateField({ sprintReplicateOnRollover: newValue })" />
            </div>
          </div>

          <div class="flex flex-col gap-1">
            <div class="font-medium text-md">Automatic reports</div>
            <div class="flex flex-col gap-4">
              <div class="flex justify-between gap-2">
                <span class="pt-2 text-sm text-md">Standup reports</span>
                <div class="flex w-72 flex-col gap-2">
                  <RecurrenceEditor
                    :recurrence="space.standupRecurrence"
                    :recurs-next-at="space.standupRecursNextAt"
                    show-time
                    @change-recurrence="(e) => updateRecurrence(e, true)"
                    @change-recurs-next-at="(e) => updateRecursNextAt(e, true)" />
                </div>
              </div>
              <div class="flex justify-between gap-2">
                <span class="pt-2 text-sm text-md">Changelog reports</span>
                <div class="flex w-72 flex-col gap-2">
                  <RecurrenceEditor
                    :recurrence="space.changelogRecurrence"
                    :recurs-next-at="space.changelogRecursNextAt"
                    show-time
                    @change-recurrence="(e) => updateRecurrence(e, false)"
                    @change-recurs-next-at="(e) => updateRecursNextAt(e, false)" />
                </div>
              </div>
            </div>
          </div>

          <PermissionManager :page="space" embedded :disabled="getIsGeneralOrPersonalSpace(space)" class="mt-4" />
        </div>
      </template>
    </template>

    <template #actions="{ entity: space }">
      <Tooltip v-if="space?.drafterDuid" :text="tooltipMessage">
        <Button
          :btn-style="ButtonStyle.PRIMARY"
          text="Create"
          is-contrast
          :disabled="!createEnabled"
          data-testid="create-space"
          @click="finishSpaceUpdate" />
      </Tooltip>
    </template>
  </Modal>
</template>
