<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { Dropdown, Menu } from "floating-vue";
import { type Component, computed, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";
import { type RouteLocationRaw, RouterLink } from "vue-router";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";

import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import Template from "~/components/dumb/Template.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { colorsByTheme } from "~/constants/style";
import { RecommendWithAiIcon } from "~/icons";
import { type CommandId, DropdownMenuItemKind, Placement } from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";
import { usePageStore } from "~/stores";

type ProcessedComponent = {
  title: string;
  items: {
    index: number;
    component: string | Component;
    args: { to: RouteLocationRaw } | { href: string; target?: string } | { onClick?: (event?: Event) => void };
    title: string;
    disabled: boolean;
    disabledReason?: string;
    selected: boolean;
    noFocus?: boolean;
    icon?: Component;
    iconArgs?: object;
    subtitle?: string;
    subtitleCopyDescription?: string;
    commandId?: CommandId;
    dataTestid?: string;
  }[];
  showTitle?: boolean;
};

const props = defineProps<{
  container?: string;
  disabled?: boolean;
  sections: DropdownMenuSection[];
  placement?: Placement;
  block?: boolean;
  heightBlock?: boolean;
  distance?: number;
  skidding?: number;
  cover?: boolean;
  widthPixels?: number;
  maxHeightPixels?: number;
  isContrast?: boolean;
  showOnHover?: boolean;
  showRecommendationButton?: boolean;
  preventCloseOnSelect?: boolean;
  hasInput?: boolean; // TODO this is a hack, remove it and solve a different way
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  referenceNode?: Component;
}>();

const emit = defineEmits<{
  recommend: [];
}>();

const currentInstance = getCurrentInstance();
const pageStore = usePageStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const wrapOnClick = (fn?: (event?: Event) => void) => (event: Event) => {
  if (props.preventCloseOnSelect) {
    event.stopImmediatePropagation();
  }
  fn?.(event);
};

let itemsCount: number;
let indexOfFocusedItem = -1;
const processedSections = computed(() => {
  const sections = [...props.sections];
  if (props.showRecommendationButton && pageStore.isOnline) {
    sections.push({
      title: "Recommend",
      items: [
        {
          title: "Fill out with AI",
          kind: DropdownMenuItemKind.BUTTON,
          icon: RecommendWithAiIcon,
          iconArgs: { class: "icon-sm text-recommendation-base dark:text-recommendation-base" },
          onClick: () => emit("recommend"),
        },
      ],
    });
  }

  itemsCount = -1;
  return sections
    .map((sect) => {
      const unhiddenItems = sect.items.filter((e) => !e.hidden);
      if (unhiddenItems.length === 0) {
        return null;
      }
      const res: ProcessedComponent = {
        title: sect.title,
        showTitle: sect.showTitle,
        items: unhiddenItems.map((item) => {
          const { title, icon, subtitle, subtitleCopyDescription, selected, disabled, commandId, iconArgs, noFocus } =
            item;
          let component;
          let args;
          switch (item.kind) {
            case DropdownMenuItemKind.INTERNAL_LINK: {
              component = RouterLink;
              args = {
                onClick: wrapOnClick(item.onClick),
                to: item.navigate?.to as RouteLocationRaw,
                target: item.navigate?.newTab ? "_blank" : undefined,
              };
              break;
            }
            case DropdownMenuItemKind.EXTERNAL_LINK: {
              component = "a";
              args = {
                onClick: wrapOnClick(item.onClick),
                href: item.navigate?.to as string,
                target: item.navigate?.newTab ? "_blank" : undefined,
              };
              break;
            }
            case DropdownMenuItemKind.BUTTON: {
              component = "div";
              args = { onClick: wrapOnClick(item.onClick) };
              break;
            }
            case DropdownMenuItemKind.COMPONENT: {
              component = item.component as Component;
              args = item.componentArgs ?? {};
              break;
            }
            default: {
              throw new Error(`Unknown dropdown menu item kind: ${item.kind}`);
            }
          }
          const calcDisabled = disabled ?? false;
          if (!calcDisabled) {
            itemsCount += 1;
          }
          return {
            index: calcDisabled ? -1 : itemsCount,
            component,
            args,
            title,
            disabled: calcDisabled,
            disabledReason: item.disabledReason,
            selected: selected ?? false,
            noFocus,
            icon,
            iconArgs,
            subtitle,
            subtitleCopyDescription,
            commandId,
            dataTestid: item.dataTestid,
          };
        }),
      };
      return res;
    })
    .filter((e): e is ProcessedComponent => !!e);
});

const dropdownEmpty = computed(() => processedSections.value.length === 0);

const rows = computed(() => {
  const res: {
    id: string;
    type: "section-start" | "section-end" | "item";
    section: ProcessedComponent;
    item?: ProcessedComponent["items"][number];
  }[] = [];
  processedSections.value.forEach((section, index) => {
    res.push({ id: `section-${index}-start`, type: "section-start", section });
    if (section.items) {
      section.items.forEach((item, itemIndex) => {
        res.push({ id: `section-${index}-${itemIndex}`, type: "item", section, item });
      });
    }
    res.push({ id: `section-${index}-end`, type: "section-end", section });
  });
  return res;
});

const placementNorm = computed(() => props.placement ?? Placement.BOTTOM_LEFT);
const defaultDistance = computed(() => props.distance ?? (props.cover ? 0 : -8));
const calcDistance = ref(defaultDistance.value);
const calcWidthPx = computed(() => props.widthPixels ?? 224);
const maxHeightPixelsNorm = computed(() => props.maxHeightPixels ?? 384);
const showOnHoverNorm = computed(() => !pageStore.isMobile && props.showOnHover);

const itemRefs = new Map();

const assignItemRef = (index: number, elem: { $el: HTMLDivElement } | null) => {
  if (!elem || !elem.$el) {
    return;
  }
  itemRefs.set(index, elem.$el);
};

const dropdownRef = ref<InstanceType<typeof Dropdown> | null>(null);
const wrapperRef = ref<HTMLDivElement | null>(null);
const recalculateDistance = () => {
  calcDistance.value = defaultDistance.value;
  if (!wrapperRef.value || !props.cover) {
    return;
  }
  calcDistance.value -= wrapperRef.value.getBoundingClientRect().height;
};
const assignWrapperRef = (elem: HTMLDivElement | null) => {
  wrapperRef.value = elem;
  recalculateDistance();
};
useResizeObserver(wrapperRef, recalculateDistance);
watch(() => props.cover, recalculateDistance);
watch(defaultDistance, recalculateDistance);

const focusDiv = ref<HTMLDivElement | null>(null);

const updateFocus = () => {
  if (indexOfFocusedItem === -1) {
    focusDiv.value?.focus();
    return;
  }
  itemRefs.get(indexOfFocusedItem)?.focus();
};

const moveUpOrDown = (isUp: boolean): boolean => {
  if ((isUp && indexOfFocusedItem <= 0) || (!isUp && (itemsCount === 0 || indexOfFocusedItem === itemsCount))) {
    return false;
  }
  indexOfFocusedItem += isUp ? -1 : 1;
  updateFocus();
  return true;
};

const onKeydown = (event: KeyboardEvent) => {
  const { key } = event;
  switch (key) {
    case "Enter":
    case "Shift":
    case "Meta":
    case "Control":
    case "Option":
    case "Alt": {
      break;
    }
    case "ArrowUp":
    case "ArrowDown": {
      const success = moveUpOrDown(key === "ArrowUp");
      if (success) {
        event.preventDefault();
      }
      break;
    }
    case "Tab": {
      event.preventDefault();
      moveUpOrDown(event.shiftKey);
      break;
    }
    default: {
      if (!props.hasInput) {
        dropdownRef.value?.hide();
      }
      break;
    }
  }
};

const onKeydownItem = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement;
  const child = target.childNodes[0] as HTMLInputElement;
  child.click();
};

const isOpen = ref(false);

const onShow = () => {
  isOpen.value = true;
  indexOfFocusedItem = -1;
  props.onAfterOpen?.();
  nextTick(() => {
    updateFocus();
  });
};

const onHide = () => {
  isOpen.value = false;
  props.onAfterClose?.();
};

const onMouseDown = (event: MouseEvent) => {
  if (!showOnHoverNorm.value || props.hasInput) {
    return;
  }
  event.preventDefault();
};

const open = () => dropdownRef.value?.show();

const close = () => dropdownRef.value?.hide();

onMounted(() => {
  if (!currentInstance) {
    return;
  }
  const instanceElem = currentInstance.subTree.el;
  if (!instanceElem?.children) {
    return;
  }

  assignWrapperRef(instanceElem.children[0] as HTMLDivElement);
});

defineExpose({
  open,
  close,
  isOpen,
});
</script>

<template>
  <Dropdown
    v-if="!dropdownEmpty"
    ref="dropdownRef"
    :container="container"
    :reference-node="() => referenceNode as Element"
    :disabled="disabled"
    :triggers="showOnHoverNorm ? ['hover', 'focus'] : undefined"
    :popper-triggers="showOnHoverNorm ? ['hover', 'focus'] : undefined"
    :placement="placementNorm"
    :distance="calcDistance"
    :skidding="skidding"
    no-auto-focus
    :theme="`dropdown-${pageStore.theme}`"
    class="flex"
    :class="{
      'max-w-full grow': block,
      'h-full': heightBlock,
      'cursor-pointer': !disabled,
    }"
    @apply-show="onShow"
    @apply-hide="onHide">
    <template v-if="!referenceNode" #default>
      <slot />
    </template>
    <template #popper>
      <Menu no-auto-focus @mousedown="onMouseDown">
        <DynamicScroller
          :items="rows"
          :min-item-size="4"
          focus
          key-field="id"
          class="overflow-y-auto rounded border app-drag-none border-md focus-ring-none"
          :class="[block && 'w-full min-w-[224px]', isContrast ? 'bg-std' : 'bg-lt']"
          :style="{
            'max-height': `calc(min(${maxHeightPixelsNorm}px,100vh))`,
            width: `${calcWidthPx}px`,
            'max-width': `${calcWidthPx}px`,
            '--background': isContrast ? colors.bgStd : colors.bgLt,
            '--highlight': isContrast ? colors.borderLt : colors.borderMd,
          }"
          @click.stop="updateFocus"
          @keydown.stop="onKeydown">
          <template v-if="!hasInput" #before>
            <div ref="focusDiv" tabindex="0" class="focus:outline-none" />
          </template>
          <template #default="{ item: { id, section, item, type }, index, active }">
            <!-- Section start and end -->
            <DynamicScrollerItem
              v-if="type !== 'item'"
              :item="{ id }"
              :active="active"
              :size-dependencies="[]"
              class="w-full truncate text-vlt border-md"
              :class="{
                'border-b pb-1': type === 'section-end' && index < rows.length - 1,
                'pt-1': type === 'section-start',
              }">
              <span
                v-if="section.showTitle && type === 'section-start'"
                class="select-none px-3 text-xs/3 font-semibold uppercase">
                {{ section.title }}
              </span>
            </DynamicScrollerItem>
            <!-- Item -->
            <DynamicScrollerItem
              v-else-if="item"
              :ref="(elem: never) => assignItemRef(item.index, elem)"
              :item="{ id }"
              :active="active"
              :size-dependencies="[]"
              :tabindex="item.disabled || item.noFocus ? undefined : 0"
              :disabled="item.disabled"
              class="focus-ring-none focus:bg-md focus-visible:bg-md"
              :class="item.disabled && 'cursor-not-allowed'"
              @keydown.enter.stop="onKeydownItem">
              <component
                :is="item.disabled && item.disabledReason ? Tooltip : Template"
                :placement="Placement.LEFT"
                :text="item.disabledReason"
                block
                :show-delay="200">
                <div v-if="item.disabled" class="absolute inset-0" />
                <component
                  :is="item.component"
                  v-bind="item.args"
                  :class="{
                    'pointer-events-none text-vlt': item.disabled,
                    'text-md': !item.disabled,
                    'cursor-pointer hover:bg-md': !item.disabled && !item.noFocus,
                  }"
                  class="group/strokes flex w-full select-none text-sm"
                  @click="!item.noFocus && dropdownRef?.hide()">
                  <DropdownMenuItemContent
                    :title="item.title"
                    :icon="item.icon"
                    :icon-args="item.iconArgs"
                    :selected="item.selected"
                    :disabled="item.disabled"
                    :subtitle="item.subtitle"
                    :subtitle-copy-description="item.subtitleCopyDescription"
                    :command-id="item.commandId"
                    :data-testid="item.dataTestid" />
                </component>
              </component>
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
        <Teleport v-if="isOpen" to="body">
          <div class="absolute inset-0" @click="close" @keydown.escape="close" />
        </Teleport>
      </Menu>
    </template>
  </Dropdown>
</template>

<style>
.v-popper--theme-dropdown .v-popper__arrow-container {
  display: none;
}
.v-popper--theme-dropdown .v-popper__wrapper {
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.v-popper--theme-dropdown .v-popper__inner {
  border-width: 0px;
  border-radius: 4px;
  background-color: transparent;
}
</style>
