<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import { backendOld } from "~/api";
import Avatar from "~/components/dumb/Avatar.vue";
import Button from "~/components/dumb/Button.vue";
import ConfirmationDialog from "~/components/dumb/ConfirmationDialog.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import MultipleEmailInput from "~/components/dumb/MultipleEmailInput.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import UpgradeConfirmationDialog from "~/components/dumb/UpgradeConfirmationDialog.vue";
import { copyAndNotify, notify } from "~/components/notifications";
import { DART_AI_DISPLAY_VALUE, DART_AI_PSEUDO_USER } from "~/constants/user";
import {
  ChevronDownIcon,
  DotsHorizontalIcon,
  DuidFieldIcon,
  InfoIcon,
  LogOutIcon,
  PremiumFeatureIcon,
  RepeatIcon,
  UsersInviteIcon,
  UsersRemoveIcon,
} from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import {
  ButtonSize,
  ButtonStyle,
  DialogMode,
  DropdownMenuItemKind,
  Entitlement,
  NotificationType,
  Placement,
  UserRole,
  UserStatus,
} from "~/shared/enums";
import type { User } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { checkout } from "~/utils/billing";
import { getItemCountText } from "~/utils/common";

const USER_STATUS_TO_PRETTY_NAME = new Map([
  [UserStatus.PENDING_SUBSCRIPTION_UPGRADE, "Upgrade required"],
  [UserStatus.INVITED, "Invited"],
  [UserStatus.PENDING_EMAIL_VERIFICATION, "Invited"],
  [UserStatus.ACTIVE, ""],
  [UserStatus.DEACTIVATED, "Removed"],
]);

const DART_AI_USER_INFO_TEXT = `${DART_AI_DISPLAY_VALUE} is a virtual assistant that helps with your work. Assign tasks to ${DART_AI_DISPLAY_VALUE} and it will take care of them for you. You are not billed for or limited by having ${DART_AI_DISPLAY_VALUE} in your workspace.`;

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const userIsAdmin = computed(() => userStore.isRoleGreaterOrEqual(UserRole.ADMIN));

const allUsers = computed(() => [...dataStore.getUserList({ includeSpecialists: true }), DART_AI_PSEUDO_USER]);

const userToRemove = ref<User | null>(null);
const userRemovalConfirmationText = computed(
  () =>
    `Are you sure you want to ${
      userStore.duid === userToRemove.value?.duid
        ? "leave this workspace?"
        : `remove ${userToRemove.value?.name || userToRemove.value?.email} from this workspace?`
    } This action cannot be undone.`
);

const hasAccessToRoles = computed(() => tenantStore.getEntitlementValue(Entitlement.ROLES));
const upgradeRequired = computed(() => {
  const userLimit = tenantStore.getEntitlementValue(Entitlement.MAX_USERS);
  return allUsers.value.length - 1 >= userLimit;
});

const removeUserModal = ref<InstanceType<typeof ConfirmationDialog> | null>(null);

const resendInviteEmailToUser = async (user: User) => {
  await backendOld.workspace.reinviteUsers([user.duid]);
  notify({
    message: "Invitation sent",
    type: NotificationType.SUCCESS,
  });
};

const removeUser = () => {
  if (!userToRemove.value) {
    return;
  }

  dataStore.removeUser(userToRemove.value);
};

const redirectToBilling = () => router.push(makeLinkToSettingsRef("billing").value);

const updateAiAssignmentEnabled = () => {
  tenantStore.aiAssignmentEnabled = !tenantStore.aiAssignmentEnabled;
  backendOld.workspace.edit("aiAssignmentEnabled", tenantStore.aiAssignmentEnabled);
};

const generateRoleDropdownSections = (user: User) => [
  {
    title: "Role",
    items: Object.values(UserRole).map((role) => ({
      title: role,
      disabled: user.role === role,
      kind: DropdownMenuItemKind.BUTTON,
      onClick: () => {
        dataStore.updateUser({ duid: user.duid, role });
      },
    })),
  },
];

const generateContextDropdownSections = (user: User) => {
  if (user.duid === DART_AI_PSEUDO_USER.duid) {
    return [
      {
        title: DART_AI_DISPLAY_VALUE,
        items: [
          {
            title: `${tenantStore.aiAssignmentEnabled ? "Disable" : "Enable"} ${DART_AI_PSEUDO_USER.name}`,
            hidden: !userIsAdmin.value,
            kind: DropdownMenuItemKind.BUTTON,
            icon: tenantStore.aiAssignmentEnabled ? UsersRemoveIcon : UsersInviteIcon,
            onClick: updateAiAssignmentEnabled,
          },
        ],
      },
    ];
  }

  return [
    {
      title: "Meta",
      items: [
        {
          title: "Resend invitation email",
          hidden: user.status !== UserStatus.INVITED,
          kind: DropdownMenuItemKind.BUTTON,
          icon: RepeatIcon,
          onClick: () => resendInviteEmailToUser(user),
        },
        {
          title: "Copy ID",
          kind: DropdownMenuItemKind.BUTTON,
          icon: DuidFieldIcon,
          onClick: () => copyAndNotify("User ID", user.duid),
        },
        {
          title: "Upgrade to premium",
          hidden: user.status !== UserStatus.PENDING_SUBSCRIPTION_UPGRADE,
          kind: DropdownMenuItemKind.BUTTON,
          icon: PremiumFeatureIcon,
          onClick: () => checkout(router.currentRoute.value.fullPath),
        },
        {
          title: user.duid === userStore.duid ? "Leave workspace" : "Remove from workspace",
          hidden: allUsers.value.length === 2 || (user.duid !== userStore.duid && !userIsAdmin.value),
          kind: DropdownMenuItemKind.BUTTON,
          icon: user.duid === userStore.duid ? LogOutIcon : UsersRemoveIcon,
          dataTestid: "remove-user",
          onClick: () => {
            userToRemove.value = user;
            removeUserModal.value?.openModal();
          },
        },
      ],
    },
  ];
};

const onContextMenu = (event: MouseEvent, user: User) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, generateContextDropdownSections(user));
};
</script>

<template>
  <div class="h-full overflow-y-auto" data-testid="teammates-view">
    <ConfirmationDialog
      ref="removeUserModal"
      :mode="DialogMode.DELETE"
      :title="userStore.duid === userToRemove?.duid ? 'Leave workspace' : 'Remove user'"
      :description="userRemovalConfirmationText"
      :confirm-text="userStore.duid === userToRemove?.duid ? 'Leave' : 'Remove'"
      cancel-text="Cancel"
      :icon="UsersRemoveIcon"
      @confirm="removeUser" />
    <div class="mx-16 lg:mx-32">
      <div class="flex h-full flex-col">
        <div>
          <div class="mb-6 space-y-1">
            <div class="flex items-center gap-4">
              <h2 class="select-none text-xl text-md">Invite teammates</h2>
              <UpgradeConfirmationDialog
                v-if="upgradeRequired"
                :feature-action="`invite more than ${getItemCountText(
                  tenantStore.getEntitlementValue(Entitlement.MAX_USERS) ?? 0,
                  'user'
                )} to your workspace`" />
            </div>
            <p class="select-none text-sm/relaxed text-lt">Invite your teammates by email to collaborate in Dart</p>
          </div>
          <MultipleEmailInput :users="allUsers" @submit="(emails, role) => actions.auth.inviteUsers(role, emails)" />
        </div>
        <div class="space-y-6">
          <table class="w-full divide-y divide-lt">
            <tbody>
              <tr>
                <th class="sticky -top-3 z-20 text-left bg-std">
                  <div class="my-2 select-none text-sm font-normal text-lt">Teammate</div>
                </th>
                <th class="sticky -top-3 z-20 min-w-20 text-left bg-std">
                  <div class="my-2 select-none text-sm font-normal text-lt">Status</div>
                </th>
                <th v-if="hasAccessToRoles" class="sticky -top-3 z-20 min-w-28 text-left bg-std">
                  <div class="m-2 select-none text-sm font-normal text-lt">Role</div>
                </th>
                <th class="sticky -top-3 z-20 w-6 bg-std" />
              </tr>

              <tr
                v-for="user in allUsers"
                :key="user.duid"
                data-testid="user-row"
                @contextmenu="(event) => onContextMenu(event, user)">
                <td class="w-full max-w-0 pr-2">
                  <div
                    class="my-1 flex items-center"
                    :class="user.duid === DART_AI_PSEUDO_USER.duid && !tenantStore.aiAssignmentEnabled && 'opacity-25'">
                    <Avatar
                      :abrev="user.abrev"
                      :is-ai="user.duid === DART_AI_PSEUDO_USER.duid"
                      circle
                      :color-hex="user.colorHex"
                      :image-url="user.imageUrl"
                      img-border
                      class="my-1 mr-2 size-10 shrink-0" />
                    <div class="ml-2 flex items-center gap-2 overflow-hidden">
                      <div class="flex flex-col overflow-hidden">
                        <span :title="user.name || user.email" class="flex-1 truncate pr-2 text-md">
                          {{ user.name || user.email }}
                        </span>
                        <span
                          v-if="user.name && user.duid !== DART_AI_PSEUDO_USER.duid"
                          :title="user.email"
                          class="flex-1 select-text truncate text-sm text-lt">
                          {{ user.email }}
                        </span>
                      </div>
                      <Tooltip
                        v-if="user.duid === DART_AI_PSEUDO_USER.duid"
                        :text="DART_AI_USER_INFO_TEXT"
                        :placement="Placement.RIGHT_TOP"
                        info
                        :skidding="-10">
                        <InfoIcon class="cursor-help rounded-full outline-none text-vlt icon-xs" />
                      </Tooltip>
                    </div>
                  </div>
                </td>

                <td class="pr-2">
                  <div
                    class="flex items-center gap-1"
                    :class="user.duid === DART_AI_PSEUDO_USER.duid && !tenantStore.aiAssignmentEnabled && 'opacity-25'">
                    <span
                      class="text-sm"
                      :class="
                        user.status === UserStatus.PENDING_SUBSCRIPTION_UPGRADE ? 'text-warning-base' : 'text-md'
                      ">
                      {{ USER_STATUS_TO_PRETTY_NAME.get(user.status) }}
                    </span>
                    <Tooltip
                      v-if="user.status === UserStatus.PENDING_SUBSCRIPTION_UPGRADE"
                      :text="`Your workspace is on the free plan. To invite more than ${getItemCountText(
                        tenantStore.getEntitlementValue(Entitlement.MAX_USERS) ?? 0,
                        'user'
                      )} to your workspace and enjoy other benefits, upgrade to premium.`"
                      :placement="Placement.RIGHT_TOP"
                      info
                      :skidding="-10">
                      <InfoIcon class="cursor-help rounded-full outline-none text-vlt icon-xs" />
                    </Tooltip>
                  </div>
                </td>

                <td v-if="hasAccessToRoles" class="w-28">
                  <DropdownMenu
                    :disabled="!userIsAdmin || user.duid === userStore.duid || user.duid === DART_AI_PSEUDO_USER.duid"
                    :sections="generateRoleDropdownSections(user)"
                    :width-pixels="140">
                    <div
                      class="flex items-center gap-1.5 rounded px-2 py-1"
                      :class="
                        user.duid === DART_AI_PSEUDO_USER.duid && !tenantStore.aiAssignmentEnabled
                          ? 'cursor-default opacity-25'
                          : !userIsAdmin || user.duid === userStore.duid || user.duid === DART_AI_PSEUDO_USER.duid
                            ? 'cursor-default'
                            : 'hover:bg-lt'
                      ">
                      <span class="select-none text-sm text-md" data-testid="user-role">
                        {{ user.role }}
                      </span>
                      <ChevronDownIcon
                        v-if="!(!userIsAdmin || user.duid === userStore.duid || user.duid === DART_AI_PSEUDO_USER.duid)"
                        class="text-lt icon-xs"
                        aria-hidden="true" />
                    </div>
                  </DropdownMenu>
                </td>

                <td class="w-6">
                  <DropdownMenu :sections="generateContextDropdownSections(user)" :placement="Placement.BOTTOM_RIGHT">
                    <div class="rounded text-lt hover:bg-lt" data-testid="manage-user">
                      <span class="sr-only">Manage user</span>
                      <DotsHorizontalIcon class="icon-md" aria-hidden="true" />
                    </div>
                  </DropdownMenu>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="upgradeRequired" class="flex items-center justify-end gap-2">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              text="See plans"
              :size="ButtonSize.LARGE"
              @click="redirectToBilling" />
            <Button
              :btn-style="ButtonStyle.PRIMARY"
              :icon="PremiumFeatureIcon"
              text="Upgrade!"
              @click="checkout(router.currentRoute.value.fullPath)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
