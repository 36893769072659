<script setup lang="ts">
import { useElementSize, useScroll, watchOnce } from "@vueuse/core";
import moment from "moment";
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";

import DraggableDivider from "~/components/dumb/DraggableDivider.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import { THROTTLE_MS } from "~/constants/app";
import { SubtaskDisplayMode } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { ThrottleManager } from "~/utils/throttleManager";

import { getDatesForInterval, getDefinition, getDiffPx, getPixelsTo, getRangeOrigin } from "./common";
import {
  BOTTOM_PADDING,
  LEFT_PANEL_MIN_PX,
  MIN_SIDE_BUFFER_PX,
  RANGE_HEIGHT,
  RIGHT_PANEL_MIN_PX,
  TARGET_SIDE_BUFFER_PX,
  TASK_ROW_HEIGHT,
  ZOOM_MAX,
  ZOOM_MIN,
} from "./constants";
import Range from "./Range.vue";
import RoadmapList from "./RoadmapList.vue";
import type { Interval, PreviewRange, RoadmapConfig, RoadmapValues } from "./shared";
import Timeline from "./Timeline.vue";

const FILLER_PX = `${RANGE_HEIGHT - 37}px`;

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

/* Refs to elements */
const timelineRef = ref<InstanceType<typeof Timeline> | null>(null);
const roadmapListRef = ref<InstanceType<typeof RoadmapList> | null>(null);

const api = computed(() => roadmapListRef.value?.api);

const getIntervalCount = (originDate: string, pixels: number, pxPerDay: number, interval: Interval) => {
  const origin = moment(originDate);
  const target = moment(origin).add(pixels / pxPerDay, "day");
  const diff = target.diff(origin, interval[1], true) / interval[0];
  return diff > 0 ? Math.ceil(diff) : Math.floor(diff);
};

/* Configuration */
const initPxPerDay = appStore.roadmapZoom;
const initTimelineInterval = getDefinition(initPxPerDay).timelineInterval;
const [initTimelineIntervalAmount, initTimelineIntervalUnit] = initTimelineInterval;
const rangeOrigin = getRangeOrigin(initTimelineIntervalUnit);
const roadmapConfig = ref<RoadmapConfig>({
  pxPerDay: initPxPerDay,
  startDate: moment(rangeOrigin)
    .add(
      initTimelineIntervalAmount *
        getIntervalCount(rangeOrigin, -TARGET_SIDE_BUFFER_PX * 2, initPxPerDay, initTimelineInterval),
      initTimelineIntervalUnit
    )
    .toISOString(),
  endDate: moment(rangeOrigin)
    .add(
      initTimelineIntervalAmount *
        getIntervalCount(rangeOrigin, TARGET_SIDE_BUFFER_PX * 3, initPxPerDay, initTimelineInterval),
      initTimelineIntervalUnit
    )
    .toISOString(),
  previewRange: null,
  activeDuid: null,
});

const onActiveChange = (activeDuid: string | null) => {
  roadmapConfig.value.activeDuid = activeDuid;
};

/* Values computed from configuration and DOM */
const scrollContainer = computed(() => timelineRef.value?.scrollContainer ?? null);
const { width: timelineWidth, height: timelineHeight } = useElementSize(scrollContainer);
const { x: timelineScrollX, y: timelineScrollY } = useScroll(scrollContainer);
const resizing = ref(false);

const roadmapValues = computed<RoadmapValues>(() => {
  const definition = getDefinition(roadmapConfig.value.pxPerDay);

  // Group tasks for subtasks
  let tasks = [...appStore.filteredAndSortedTasksInPage];
  if (appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT) {
    tasks = tasks.filter(
      (task) => !dataStore.getAncestorDuids(task).some((ancestor) => !dataStore.getTaskByDuid(ancestor)?.expanded)
    );
  }

  return {
    definition,
    tasks,
    rangeDates: getDatesForInterval(roadmapConfig.value, definition.rangeInterval),
    timelineDates: getDatesForInterval(roadmapConfig.value, definition.timelineInterval),
    scrollWidth:
      roadmapConfig.value.pxPerDay *
      (moment(roadmapConfig.value.endDate).diff(roadmapConfig.value.startDate, "day") + 1),
    scrollHeight: (tasks.length + (pageStore.isPublicView ? 0 : 1)) * TASK_ROW_HEIGHT + BOTTOM_PADDING - RANGE_HEIGHT,
    width: timelineWidth.value,
    height: timelineHeight.value,
    scrollX: timelineScrollX.value,
    scrollY: timelineScrollY.value,
    zoomInDisabled: roadmapConfig.value.pxPerDay >= ZOOM_MAX,
    zoomOutDisabled: roadmapConfig.value.pxPerDay <= ZOOM_MIN,
    resizing: resizing.value,
  };
});

/* Zoom */
let justZoomed = false;

const expandRangeIfNeeded = async (targetDate?: string) => {
  if (!timelineScrollX.value) {
    return;
  }
  if (justZoomed) {
    justZoomed = false;
    return;
  }

  const prevLeftDatetime = moment(roadmapConfig.value.startDate)
    .add(roadmapValues.value.scrollX / roadmapConfig.value.pxPerDay, "day")
    .toISOString();

  const { scrollX, scrollWidth, width } = roadmapValues.value;
  const { timelineInterval } = roadmapValues.value.definition;
  const [timelineIntervalAmount, timelineIntervalUnit] = timelineInterval;

  const leftSpace = targetDate ? getPixelsTo(roadmapConfig.value, targetDate) - width / 3 : scrollX;
  const resizeLeft = leftSpace <= MIN_SIDE_BUFFER_PX;

  const rightSpace = targetDate
    ? getDiffPx(roadmapConfig.value, targetDate, roadmapConfig.value.endDate) - (width * 2) / 3
    : scrollWidth - scrollX - width;
  const resizeRight = rightSpace <= MIN_SIDE_BUFFER_PX;

  if (!resizeLeft && !resizeRight) {
    return;
  }

  /* Expand to the left */
  if (resizeLeft) {
    const intervalCount = getIntervalCount(
      roadmapConfig.value.endDate,
      -TARGET_SIDE_BUFFER_PX + leftSpace,
      roadmapConfig.value.pxPerDay,
      timelineInterval
    );
    roadmapConfig.value.startDate = moment(roadmapConfig.value.startDate)
      .add(timelineIntervalAmount * intervalCount, timelineIntervalUnit)
      .toISOString();
  }

  /* Expand to the right */
  if (resizeRight) {
    const intervalCount = getIntervalCount(
      roadmapConfig.value.endDate,
      TARGET_SIDE_BUFFER_PX - rightSpace,
      roadmapConfig.value.pxPerDay,
      timelineInterval
    );
    roadmapConfig.value.endDate = moment(roadmapConfig.value.endDate)
      .add(timelineIntervalAmount * intervalCount, timelineIntervalUnit)
      .toISOString();
  }

  if (targetDate) {
    return;
  }

  await nextTick();
  timelineRef.value?.scrollTo(getPixelsTo(roadmapConfig.value, prevLeftDatetime));
};

const saveManager = new ThrottleManager((pxPerDay: number) => {
  appStore.setRoadmapZoom(pxPerDay);
}, THROTTLE_MS);

const zoom = async (newPxPerDay: number) => {
  resizing.value = true;
  const prevMiddleDatetime = moment(roadmapConfig.value.startDate)
    .add((roadmapValues.value.scrollX + roadmapValues.value.width / 3) / roadmapConfig.value.pxPerDay, "day")
    .toISOString();

  roadmapConfig.value.pxPerDay = Math.max(Math.min(newPxPerDay, ZOOM_MAX), ZOOM_MIN);
  saveManager.run(roadmapConfig.value.pxPerDay);

  await expandRangeIfNeeded(prevMiddleDatetime);

  await nextTick();
  justZoomed = true;
  timelineRef.value?.scrollTo(getPixelsTo(roadmapConfig.value, prevMiddleDatetime) - roadmapValues.value.width / 3);

  resizing.value = false;
};

const onSavePaneSize = (size: number) => {
  appStore.setRoadmapTaskListWidthPx(size);
};

const onAfterPaneResize = () => {
  const apiNorm = api.value;
  if (!apiNorm || apiNorm.isDestroyed()) {
    return;
  }
  apiNorm.sizeColumnsToFit();
};

watchOnce(
  () => timelineScrollX.value,
  () => expandRangeIfNeeded(rangeOrigin)
);

/* Scroll expansion */
const onTimelineScroll = (): void => {
  expandRangeIfNeeded();
};

/* Show preview range */
const updatePreviewRange = (previewRange: PreviewRange): void => {
  roadmapConfig.value.previewRange = previewRange;
};

/* Expose to App */
onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.roadmap = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});
onUnmounted(() => {
  appStore.roadmap = null;
  saveManager.destroy();
});

defineExpose({
  api,
  roadmapValues,
  roadmapConfig,
});
</script>

<template>
  <div class="absolute inset-0 size-full overflow-hidden p-4 bg-std" data-testid="roadmap">
    <div class="flex size-full flex-col overflow-hidden">
      <!-- Body -->
      <DraggableDivider
        :pane-width-px="appStore.roadmapTaskListWidthPx"
        :pane-min-px="LEFT_PANEL_MIN_PX"
        :content-min-px="RIGHT_PANEL_MIN_PX"
        styles="flex flex-col"
        left
        @after-resize="onAfterPaneResize"
        @save="onSavePaneSize">
        <template #pane>
          <div class="flex size-full flex-col">
            <div class="w-full border-r border-md" :style="{ height: FILLER_PX, paddingTop: FILLER_PX }" />
            <RoadmapList ref="roadmapListRef" :roadmap-config="roadmapConfig" :tasks="roadmapValues.tasks" />
          </div>
        </template>
        <template #default>
          <!-- Headers - Range -->
          <Range :roadmap-config="roadmapConfig" :roadmap-values="roadmapValues" @zoom="zoom" />
          <!-- Timeline -->
          <Timeline
            ref="timelineRef"
            :roadmap-config="roadmapConfig"
            :roadmap-values="roadmapValues"
            @active-change="onActiveChange"
            @scroll="onTimelineScroll"
            @zoom="zoom"
            @update-preview-range="updatePreviewRange" />
        </template>
      </DraggableDivider>
    </div>
    <PageEmptyState
      v-if="roadmapValues.tasks.length === 0"
      class="bg-gradient-to-b from-transparent to-50% to-std"
      :is-filter-mode="appStore.allTasksInPage.length > 0" />
  </div>
</template>
