<script lang="ts" setup>
import type { Component } from "vue";

import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import DropdownMenuItemContent from "~/components/dumb/DropdownMenuItemContent.vue";
import { Placement } from "~/shared/enums";
import type { DropdownMenuSection } from "~/shared/types";

defineProps<{
  title: string;
  icon?: Component;
  iconArgs?: object;
  preventCloseOnSelect?: boolean;
  sections: DropdownMenuSection[];
}>();
</script>

<template>
  <DropdownMenu
    :sections="sections"
    :placement="Placement.RIGHT_TOP"
    :prevent-close-on-select="preventCloseOnSelect"
    show-on-hover
    class="w-full"
    @keydown.enter.stop
    @click.stop>
    <DropdownMenuItemContent :icon="icon" :icon-args="iconArgs" :title="title" is-submenu />
  </DropdownMenu>
</template>
